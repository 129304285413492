































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Certificate from '@/store/entities/courses/certificate';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView, VuePerfectScrollbar }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.certificate = Util.extend(true, {}, this.$store.state.certificate.editModel); 
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class CertificateView extends ListBase {
    entity: string = 'certificate';
    @Prop({ type: Boolean, default: false }) value: boolean;
    certificate: Certificate = new Certificate();
    modalShow: boolean = false;
    url: string = url;
    modalSetting: any = {
        entity: "certificate",
        titleFull: 'Datos del certificado',
        width: '800px',
        showForm: false,
        showSaveButton: false,
        showFootActions: false,
        showFloatFootActions: false,
        fullscreen: true
    };
    scrollSettings: any = {
        maxScrollbarLength: 160
    };
          
    get listEnroll() {
        return this.$store.state.enrollment.list;
    }

    get approvedCount() {
        let state = Util.abp.list.get('E_ENROLL','AP');
        return this.listEnroll.filter(item => {
            return item.state_id == state.id;
        }).length;
    }

    get disaprovedCount() {
        let state = Util.abp.list.get('E_ENROLL', 'AP');
        return this.listEnroll.filter(item => {
            return item.state_id != state.id;
        }).length;
    }

    get loadingEnroll() {
        return this.$store.state.enrollment.loading;
    }

    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get loadingSent() {
        return this.$store.state[this.entity].loadingSent;
    };

    get totalCountEnroll() {
        return this.$store.state.enrollment.list.length;
    }

    getCertificateUrl(item) {
        return url + "api/certificate-student-pdf?id=" + item.certificate_id + "&enrollment=" + item.enrollment_id + "&pdf=1";
    }

    getCertificatesUrl() {
        return url + "api/certificate-student-pdf?id=" + this.certificate.id + "&pdf=1";
    }

    async loadEnrollments() {
        await this.$store.dispatch({
            type: 'enrollment/getAll',
            data: { certificate: this.certificate.id, perPage: 1000 }
        });
    }

    getInitials(string) {
        return Util.getInitials(string);
    }
    
    getState(state_id) {
        return Util.abp.list.getItem(state_id);
    }

    initForm() {
        this.loadEnrollments();
    }

    async sent(item) {
        let newModel = await this.$store.dispatch({
            type: 'certificate/sent',
            data: {
                id: this.certificate.id,
                enrollment_id: item.enrollment_id,
            }
        });
        this.certificate.nro_sent = newModel.nro_sent;
        this.certificate.sent = newModel.sent;
        this.loadEnrollments();
        this.$emit('save-success');

        Util.abp.notify.success(this, 'El certificado han sido enviado!');
    }

    async sentAll(item) {
        let newModel = await this.$store.dispatch({
            type: 'certificate/sent',
            data: {
                id: this.certificate.id,
                enrollment_id: null,
            }
        });
        this.certificate.nro_sent = newModel.nro_sent;
        this.certificate.sent = newModel.sent;
        this.loadEnrollments();
        this.$emit('save-success');

        Util.abp.notify.success(this, 'El certificados han sido enviados!');
    }
           
    created() {
    }

    mounted() {
    }

    
    cancel() {
        this.$emit('input', false);
    }
}
