























































































































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Certificate from '@/store/entities/courses/certificate';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView, VuePerfectScrollbar }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.certificate = Util.extend(true, {}, this.$store.state.certificate.editModel); 
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class CertificateForm extends ListBase {
    entity: string = 'certificate';
    @Prop({ type: Boolean, default: false }) value: boolean;
    certificate: Certificate = new Certificate();
    modalShow: boolean = false;
    e1: any = 1;
    selectedCourse: any = null;
    selectedCert: any = null;
    selectedEnroll: Array<any> = [];
    tabModel: any = 0;
    urlPhoto: string = url;
    selection: any = '0';
    modalSetting: any = {
        entity: "certificate",
        titleFull: 'Emitir Certificado',
        width: '800px',
        showForm: false,
        showSaveButton: false,
        fullscreen: true
    };
    scrollSettings: any = {
        maxScrollbarLength: 160
    };

    get list() {
        return this.$store.state.courseclass.list;
    }

    get listTemplate() {
        return this.$store.state.certificatetemplate.list;
    }

    get listEnroll() {
        return this.$store.state.enrollment.list;
    }

    get loadingEnroll() {
        return this.$store.state.enrollment.loading;
    }

    get loadingCert() {
        return this.$store.state.certificatetemplate.loading;
    };

    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get totalCount() {
        return this.$store.state.courseclass.list.length;
    }

    get totalCountEnroll() {
        return this.$store.state.enrollment.list.length;
    }

    get loadingClass() {
        return this.$store.state.courseclass.loading;
    }

    get courseclass() {
        return this.$store.state.courseclass.list[this.selectedCourse];
    }

    get template() {
        return this.listTemplate[this.selectedCert];
    }

    get templatePages() {
        let pages = [];
        let data = {
            'fecha': (this as any).$moment(this.courseclass.start_date).format("dddd D [de] MMMM [del] YYYY"),
            'mes': (this as any).$moment().format("MMMM [del] YYYY"),
            'nombre_apellido': (this.firstEnroll.student != null ? this.firstEnroll.student.fullname : ''),
            'curso_nombre': this.courseclass.course.name + (this.courseclass.title != null ? ' ' + this.courseclass.title : ''),
            'curso_descripcion': this.courseclass.course.description,
            'tema': this.courseclass.theme,
            'duracion': String(this.courseclass.academic_hours).padStart(2, "0"),
            'ponente': (this.courseclass.teacher != null ? this.courseclass.teacher.fullname : ''),
            'descripcion_ponentes': this.courseclass.teachers_text,
            'codigo': this.courseclass.prefix_code,
            'calificacion': this.firstEnroll.ranking,
        };
        
        this.template.pages.forEach(item => {
            let newItem = Util.extend(true, {}, item); 
            newItem.header_content = Util.replaceEmailContent(item.header_content, data);
            newItem.body_content = Util.replaceEmailContent(item.body_content, data);
            newItem.footer_content = Util.replaceEmailContent(item.footer_content, data);
            pages.push(newItem);
        });
        return pages;
    }

    get firstEnroll() {
        if (this.selectedEnroll == null) return {};
        if (this.selectedEnroll.length == 0) return {};
        if (this.listEnroll[this.selectedEnroll[0]] == undefined) return {};
        return this.listEnroll[this.selectedEnroll[0]];
    }

    get isEditing() {
        return this.certificate.id > 0;
    }

    get approvedCount() {
        let state = Util.abp.list.get('E_ENROLL', 'AP');
        return this.listEnroll.filter(item => {
            return item.state_id == state.id;
        }).length;
    }


    async loadClases() {
        await this.$store.dispatch({
            type: 'courseclass/getFinished'
        });
    }

    async loadTemplates() {
        await this.$store.dispatch({
            type: 'certificatetemplate/getAll'
        });
    }
    
    async loadEnrollments() {
        await this.$store.dispatch({
            type: 'enrollment/getAll',
            data: { course_class: this.courseclass.id, perPage: 2000 }
        });
    }

    async showTemplates() {
        this.e1 = 2;
        await this.loadTemplates();
    }
    
    async showEnrolls() {
        this.e1 = 3;
        await this.loadEnrollments();
    }

    changeSelection(value) {
        this.selectedEnroll = [];
        if (value != '0') {
            let approved = Util.getTypeValueByCode(this, 'E_ENROLL', 'AP').id;
            this.listEnroll.forEach((item, k) => {
                if (value == '2')
                    this.selectedEnroll.push(k);
                else if (value == '1' && item.state_id == approved)
                    this.selectedEnroll.push(k);
            });
        }
    }

    getInitials(string) {
        return Util.getInitials(string);
    }
    
    getState(state_id) {
        return Util.abp.list.getItem(state_id);
    }

    imageSrc(image) {
        return url + image;
    }

    initForm() {
        this.e1 = 1;
        this.selectedCourse = null;
        this.selectedCert = null;
        this.selectedEnroll = [];
        if (!this.isEditing) {
            this.loadClases();
            this.modalSetting.titleFull = 'Emitir Certificado';
        } 
    }

    async saveCertificate() {
        if (this.selectedEnroll == null || (this.selectedEnroll != null && this.selectedEnroll.length == 0)) {
            Util.abp.notify.success(this, 'Debe seleccionar al menos un asistente!');
            return;
        }

        let students = [];
        this.selectedEnroll.forEach(item => {
            let enroll = this.listEnroll[item];
            students.push({
                enrollment_id: enroll.id,
                student_id: enroll.student_id
            });
        });

        await this.$store.dispatch({
            type: 'certificate/create',
            data: {
                certificate_template_id: this.template.id,
                course_id: this.courseclass.course_id,
                course_class_id: this.courseclass.id,
                students: students
            }
        });
        this.$emit('save-success');
        this.cancel();

        Util.abp.notify.success(this, 'Los certificados han sido generados!');
    }
           
    created() {
    }

    mounted() {
    }

    
    cancel() {
        this.$emit('input', false);
    }
}
