

































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import CertificateForm from './certificate-form.vue';
import CertificateView from './certificate-view.vue';

class PageCertificateRequest extends PageRequest {
    course: number = null;
    code: string = '';
    name: string = '';
}

@Component({
    components: { ListView, FormBase, CertificateForm, CertificateView }
})
export default class Certificates extends ListBase {
    entity: string = 'certificate';
    pagerequest: PageCertificateRequest = new PageCertificateRequest();
    filterForm: boolean = true;
    modalViewShow: boolean = false;

    get exluddeIds() {
        let ids = [];
        this.$store.state.certificate.list.forEach(item => {
            if (item.nro_sent>0)
                ids.push(item.id);
        });

        return { delete: ids };
    }

    view() {
        this.modalViewShow = !this.modalViewShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    } 

    async afterSave() {
        this.createModalShow = false;
        let list: any = this.$refs.listView
        await list.getpage();
    }

    filterItems = [
        this.gItem({ type: 'text', prop: 'code', label: 'Buscar por Codigo', size: 2, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'object', prop: 'course', label: 'Curso', size: 4, hideDetails: true, onchange: this.afterSave }),
        //this.gItem({ type: 'text', prop: 'name', label: 'Descripcion de Curso', size: 4, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]

    columns = [
        this.gColO({ type: 'text', value: 'id', text: 'Codigo', main: true }),
        this.gCol('course_class.name', 'Nombre del Curso', 'text'),
        this.gCol('issue_date', 'Fecha de Envio', 'date'),
        this.gCol('sent', 'Enviado', 'boolean'),
        this.defaultActions('view|delete')
    ];

    imageSrc(image) {
        return url + image;
    }

    async created() {
        //this.getpage();            
    }
}